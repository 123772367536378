<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 11 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 0L5.5 5L10.5 0H0.5Z"
      fill="#3D3A5C"
    />
  </svg>
</template>

<script>
export default {
  name: 'ic-bold-arrow',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped></style>
