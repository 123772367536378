import { render, staticRenderFns } from "./ic-bold-arrow.vue?vue&type=template&id=58bb0304&scoped=true"
import script from "./ic-bold-arrow.vue?vue&type=script&lang=js"
export * from "./ic-bold-arrow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58bb0304",
  null
  
)

export default component.exports